/** @format */

import React from "react";
import "./cta.css";

const CTA = () => (
  <div className="gpt3__cta" id="cta">
    <div className="gpt3__cta-content">
      <h3>Insgesamt ein Rundum-Sorglos-Paket für Sie aus einer Hand</h3>
    </div>
    <div className="gpt3__cta-btn">
      <a href="mailto:info@clc-gmbh.com">
        <button type="button">Kontaktieren Sie uns</button>
      </a>
    </div>
  </div>
);

export default CTA;
