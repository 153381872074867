/** @format */

import React from "react";
import CookieConsent from "react-cookie-consent";

import { Footer, Blog, Possibility, Features, WhatGPT3, Header } from "./containers";
import { CTA, Brand, Navbar } from "./components";

import "./App.css";

const App = () => (
  <div className="App">
    {/* <CookieConsent>Wir verwenden ausschließlich technisch notwendige Einstellungen.</CookieConsent> */}
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    {/* <Brand /> */}
    <WhatGPT3 />
    <Features />
    {/* <Possibility /> */}
    <CTA />
    {/* <Blog /> */}
    <Footer />
  </div>
);

export default App;
