/** @format */

import React from "react";
import Popup from "reactjs-popup";
import gpt3Logo from "../../assets/CLC_500px.svg";
import "./footer.css";

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Sie wollen mit CLC eine starke Partnerschaft starten</h1>
    </div>

    <div className="gpt3__footer-btn">
      <a href="mailto:info@clc-gmbh.com">Dann kontacktieren Sie uns</a>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>
          CLC GmbH
          <br />
          Simonshöfchen 55
          <br />
          D-42327 Wuppertal
          <br />
          Tel. Nr.: +49 202 87018691
          <br />
          E-Mail: info@clc-gmbh.com
        </p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <Popup trigger={<p>Impressum</p>} modal nested>
          {(close) => (
            <div className="modal">
              <button className="close" onClick={close}>
                &times;
              </button>
              <div className="header"> Impressum </div>
              <div className="content">
                {" "}
                <p>Angaben gemäß §5 TMG</p>
                <br />
                <p>
                  CLC GmbH
                  <br />
                  Simonshöfchen 55
                  <br />
                  D-42327 Wuppertal
                </p>
                <br />
                <p>
                  Amtsgericht Wuppertal
                  <br />
                  HRB 30726
                </p>
                <br />
                <p>
                  Geschäftsführer
                  <br />
                  André Skotarek, Stefan Skotarek
                </p>
                <br />
                <p>
                  Tel. Nr.:  +49 202 87018691
                  <br />
                  E-Mail: info@clc-gmbh.com
                </p>
                <br />
                <p>
                  UST-Id.-Nr. DE 331 109 100
                  <br />
                  Steuernummer: 132/5916/2783
                </p>
                <br />
                <p>Verantwortlich für den Inhalt</p>
                <p>CLC GmbH</p>
                <br />
                <br />
                <h2>Haftungsausschluß</h2>
                <br />
                <p>Haftung für Inhalte</p>
                <br />
                <p>
                  Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit
                  und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
                  gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                  Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                  gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
                  rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                  Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                  jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
                  von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                </p>
                <br />
                <p>Haftung für Links</p>
                <br />
                <p>
                  Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
                  haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                  verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                  verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                  Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
                  Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                  zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                </p>
                <br />
                <p>Urheberrecht</p>
                <br />
                <p>
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                  Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                  Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                  Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
                  gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                  Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten
                  Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
                  Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </p>
              </div>
            </div>
          )}
        </Popup>
        <Popup trigger={<p>Datenschutz</p>} modal nested>
          {(close) => (
            <div className="modal">
              <button className="close" onClick={close}>
                &times;
              </button>
              <div className="header"> Datenschutz </div>
              <div className="content">
                {" "}
                <p>
                  Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
                  unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben
                  werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                  ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                  <br />
                  Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                  Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                  nicht möglich.
                  <br />
                  Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
                  Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
                  ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
                  Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                </p>
                <br />
                <p>Google AdSense</p>
                <br />
                <p>
                  Diese Website benutzt Google Adsense, einen Webanzeigendienst der Google Inc., USA (”Google”). Google
                  Adsense verwendet sog. ”Cookies” (Textdateien), die auf Ihrem Computer gespeichert werden und die eine
                  Analyse der Benutzung der Website durch Sie ermöglicht. Google Adsense verwendet auch sog. ”Web
                  Beacons” (kleine unsichtbare Grafiken) zur Sammlung von Informationen. Durch die Verwendung des Web
                  Beacons können einfache Aktionen wie der Besucherverkehr auf der Webseite aufgezeichnet und gesammelt
                  werden. Die durch den Cookie und/oder Web Beacon erzeugten Informationen über Ihre Benutzung diese
                  Website (einschließlich Ihrer IP-Adresse) werden an einen Server von Google in den USA übertragen und
                  dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website im Hinblick
                  auf die Anzeigen auszuwerten, um Reports über die Websiteaktivitäten und Anzeigen für die
                  Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
                  verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an
                  Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von
                  Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in
                  Verbindung bringen. Das Speichern von Cookies auf Ihrer Festplatte und die Anzeige von Web Beacons
                  können Sie verhindern, indem Sie in Ihren Browser-Einstellungen ”keine Cookies akzeptieren” wählen (Im
                  MS Internet-Explorer unter ”Extras &gt; Internetoptionen &gt; Datenschutz &gt; Einstellung”; im
                  Firefox unter ”Extras &gt; Einstellungen &gt; Datenschutz &gt; Cookies”); wir weisen Sie jedoch darauf
                  hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich
                  nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie
                  erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck
                  einverstanden.
                </p>
                <br />
                <p>Cookies</p>
                <br />
                <p>
                  Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen
                  Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
                  effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt
                  werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind so genannte
                  „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf
                  Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
                  beim nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über das
                  Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies
                  für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim
                  Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser
                  Website eingeschränkt sein.
                </p>
                <br />
                <p>Server-Log-Files</p>
                <br />
                <p>
                  Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log
                  Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                </p>
                <br />
                <p>
                  Browsertyp/ Browserversion, verwendetes Betriebssystem, Referrer URL, Hostname des zugreifenden
                  Rechners, Uhrzeit der Serveranfrage
                </p>
                <br />
                <p>
                  Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen
                  Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn
                  uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
                </p>
                <br />
                <p>Kontaktformular</p>
                <br />
                <p>
                  Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
                  inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall
                  von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                </p>
                <br />
                <p>Newsletterdaten</p>
                <br />
                <p>
                  Wenn Sie den auf der Webseite angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine
                  E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der
                  angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten
                  werden nicht erhoben. Diese Daten verwenden wir ausschließlich für den Versand der angeforderten
                  Informationen und geben sie nicht an Dritte weiter. Die erteilte Einwilligung zur Speicherung der
                  Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit
                  widerrufen , etwa über den „Austragen“-Link im Newsletter.
                </p>
                <br />
                <p>Datenschutzerklärung für die Nutzung von Google Analytics</p>
                <br />
                <p>
                  Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc.
                  1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Google Analytics verwendet sog. “Cookies”. Das
                  sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der
                  Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser
                  Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
                </p>
                <br />
                <p>
                  Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google
                  jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
                  Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
                  IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des
                  Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website
                  auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
                  Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu
                  erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
                  anderen Daten von Google zusammengeführt.
                </p>
                <br />
                <p>
                  Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
                  verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                  Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung
                  der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
                  IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter
                  dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:
                  http://tools.google.com/dlpage/gaoptout?hl=de
                </p>
                <br />
                <p>Datenschutzerklärung für die Nutzung von Facebook-Plugins (Like-Button)</p>
                <br />
                <p>
                  Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker Way,
                  Menlo Park, California 94025, USA, integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo
                  oder dem “Like-Button” (“Gefällt mir”) auf unserer Seite. Eine Übersicht über die Facebook-Plugins
                  finden Sie hier: http://developers.facebook.com/docs/plugins/.
                </p>
                <br />
                <p>
                  Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser
                  und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer
                  IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook “Like-Button” anklicken während Sie in
                  Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem
                  Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto
                  zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
                  übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden
                  Sie in der Datenschutzerklärung von Facebook unter http://de-de.facebook.com/policy.php. Wenn Sie
                  nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook- Nutzerkonto zuordnen kann,
                  loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
                </p>
                <br />
                <p>Auskunft, Löschung, Sperrung</p>
                <br />
                <p>
                  Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen
                  Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf
                  Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
                  personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns
                  wenden.
                </p>
              </div>
            </div>
          )}
        </Popup>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2024 CLC. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
