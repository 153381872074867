/** @format */

import React from "react";
import Feature from "../../components/feature/Feature";
import "./whatGPT3.css";

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="leistungen">
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Leistungen</h1>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature
        title="Produktberatung"
        text="Im Rahmen unserer Produktberatung unterstützen wir Sie bei der Umsetzung der europäischen und nationalen gesetzlichen Vorschriften.

          Hierbei speziell im Hinblick auf:
          
          das Lebensmittel- und Futtermittelgesetzbuch (LFGB)
          das Wasch- und Reinigungsmittelgesetz (WRMG)
          die Verordnung (EG) Nr. 648/2004 über Detergenzien
          das Chemikaliengesetz
          die Verordnung (EG) Nr. 1272/2008 (CLP)
          die Verordnung (EG) Nr. 1907/2006 (REACH)
          die Verordnung (EG) Nr. 1223/2009 über kosmetische Mittel
          die Verordnung (EU) Nr. 528/2012 (Biozid-Verordnung)
          die Fertigpackungsverordnung (FertigPackV)"
      />
      <Feature
        title="Kosmetische Mittel"
        text="Erstellung und Unterstützung der Produktinformationsdatei gemäß Artikel 11 der Verordnung (EG) 1223/2009
          Deklaration nach INCI (International Nomenclature Cosmetic Ingredients)
          Sicherheitsbewertung von kosmetischen Mitteln gemäß Art. 10
          Notifizierung von kosmetischen Mitteln gemäß Art. 13
          Bewertung der Verkehrsfähigkeit inkl. Überprüfung der Kennzeichnungselemente für kosmetische Mittel"
      />
      <Feature
        title="Wasch- und Reinigungsmittel"
        text="Einhaltung der Anforderungen des Wasch- und Reinigungsmittelgesetztes (WRMG) und der Verordnung (EG) Nr. 648/2004 über Detergenzien
          Deklaration der Inhaltsstoffe gemäß Anhang VII der Verordnung (EG) Nr. 648/2004
          Warnhinweise nach A.I.S.E (International Association for Soaps, Detergents and Maintenance Products)
          Mitteilung von Wasch- und Reinigungsmitteln an das BfR gemäß §10 WRMG
          Erstellung der Datenblätter für Verbraucher
          Erstellung der Datenblätter für Mediziner
          Bewertung der Verkehrsfähigkeit inkl. Überprüfung der Kennzeichnungselemente für WPR und andere Bedarfsgegenstände (Deklarations- und Kennzeichnungsüberprüfung)"
      />
    </div>

    <div className="gpt3__whatgpt3-container">
      <Feature
        title="Produktmeldungen"
        text="Mitteilung von gefährlichen Produkten an das BfR gemäß § 16e ChemG
          Mitteilung von Biozid-Produkten an das BfR gemäß §16e ChemG
          Meldung von Biozid-Produkten an die BAuA gemäß Mitteilungsverordnung
          Anmeldung von gefährlichen Produkten bei den zuständigen Behörden z.B. in Belgien, Portugal, Polen, Spanien und der Schweiz
          Anmeldung bei der ECHA nach Artikel 45 in Verbindung mit Anhang VIII der CLP inkl. der Erstellung der UFI (in Vorbereitung)"
      />
      <Feature
        title="Sicherheitsdatenblätter"
        text="Sicherheitsdatenblätter gemäß Artikel 31 der REACH-Verordnung

          Erstellung von EG-Sicherheitsdatenblättern in den jeweiligen europäischen Landessprachen (DE, EN, FR, ES, IT, PL, etc.)"
      />
      <Feature
        title="Technischer Support"
        text="Koordination und Betreuung von extern durchgeführten Prüfungen
          Bearbeitung von Beanstandungen durch Überwachungsbehörden
          Bearbeitung von Beanstandungen durch Kunden
          Bearbeitung von Beanstandungen durch Verbraucher"
      />
    </div>
  </div>
);

export default WhatGPT3;
