/** @format */

import React from "react";
import Feature from "../../components/feature/Feature";
import "./features.css";

const featuresData = [
  {
    title: "IKW-Mitgliederfirmen",
    text: "Zusammenarbeit mit IKW-Mitgliederfirmen sowie Kunden, die zum IGA Aerosolverband gehören",
  },
  {
    title: "Seminare",
    text: "Regelmäßige Teilnahme an DGK/IKW-Sicherheitsbewerter Seminaren",
  },
  {
    title: "Teilnahme",
    text: "Regelmäßige Teilnahme an IKW-Veranstaltungen",
  },
  {
    title: "Zusammenarbeit",
    text: "Zusammenarbeit mit Kunden aus den Bereichen Kosmetik sowie Wasch-, Putz- und Reinigungsmittel",
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Erfahrung</h1>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
