/** @format */

import React from "react";
import ai from "../../assets/dna.png";
import "./header.css";

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Willkommen bei der CLC GmbH</h1>
      <p>
        Die CLC GmbH ist ein Dienstleister für die Bereiche Kosmetika, Wasch-, Putz- und Reinigungsmittel und andere
        Bedarfsgegenst&auml;nde sowie Biozide.
        <br />
        <br />
        Unsere Mitarbeiter/innen kommen aus den Branchen der Chemie, Biologie und Lebensmittelchemie und sind
        spezialisiert in vielen Bereichen regulatorischer Angelegenheiten zu Kosmetika, Wasch-, Putz- und
        Reinigungsmittel, Bedarfsgegenst&auml;nde sowie Biozide.
      </p>
      <br />
      <br />
      <h2 className="gradient__text">Was uns Auszeichnet</h2>
      <li>Individuelle Beratung zu Ihren Wünschen</li>
      <li>Kurze Entscheidungswege</li>
      <li>Zuverlässige Betreuung durch einen Ansprechpartner</li>
      <li>Schlanke Strukturen und schnelle Reaktionszeiten</li>
      {/*}
      <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Get Started</button>
      </div>

      <div className="gpt3__header-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div>
*/}
    </div>

    <div className="gpt3__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
